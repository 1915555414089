import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import NavigationLinks from './NavigationLinks';
import Image from '../Image';
import styles from './styles.module.css';
import { formColor } from '../../helper';

class NavigationBar extends React.Component {

  constructor(props) {

    super(props);

    const { logo } = props.pageContext;
    const { sitename } = props.pageContext;
    const { themeData } = props.pageContext;
    const section = props.pageContext.navigation;

    const burgerBreak = Number(section.data[0].burger) || 5;
    const divider = section.data[0].divider || 1;
    const linkStyle = section.data[0].linkStyle || 1;

    this.state = {
      links: this.props.pageContext.navLinks,
      secondaryLinks: this.props.pageContext.secondaryLinks,
      burgerBreak,
      divider,
      linkStyle,
      recStyles: section.data[0].styles,
      logo,
      sitename,
      themeData,
      overlay: section.data[0].overlay,
      full: section.data[0].full,
      fixed_top: section.data[0].fixed_top,
      layout: section.data[0].layout,
      infoRow: section.data[0].infoRow,
      matches: null,
    };

    this.updateMatches = this.updateMatches.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  render() {

    let result;

    if (this.state.matches !== null) {

      const isScrolling = this.props.isScrolling
        && this.props.pageContext.navigation.data[0].fixed_top;

      const titleStyle = {
        fontFamily: this.state.themeData.typography.logo.name !== ''
          ? this.state.themeData.typography.logo.name
          : this.state.themeData.typography.default.name,
        fontSize: '38px',
        lineHeight: this.state.themeData.typography.logo.lineHeight
          ? this.state.themeData.typography.logo.lineHeight
          : this.state.themeData.typography.default.lineHeight,
        color: this.state.recStyles.font.titleColor !== ''
          ? this.state.recStyles.font.titleColor
          : this.state.themeData.colors[3],
        fontWeight: 700,
        textTransform: 'none',
      };

      let style;

      if (this.state.recStyles) {

        let { backgroundColor } = this.state.recStyles;
        if (
          this.state.recStyles.backgroundColor.solid === ''
          && this.state.recStyles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#FFFFFF' };

        }

        const opacity = isScrolling === false
          ? this.state.recStyles.backgroundColor.opacity : 1;
        const bgColor = formColor(
          backgroundColor,
          false,
          opacity,
        );

        let sColor;
        if (this.state.recStyles.shadow.active) {

          let solid = this.state.recStyles.shadow.color;
          if (this.state.recStyles.shadow.color === '') {

            [, , solid] = this.state.themeData.colors;

          }

          sColor = formColor(
            { solid },
            false,
            this.state.recStyles.shadow.opacity,
          );

        }

        const x = this.state.recStyles.shadow.active ? this.state.recStyles.shadow.x : undefined;
        const y = this.state.recStyles.shadow.active ? this.state.recStyles.shadow.y : undefined;
        const blur = this.state.recStyles.shadow.active
          ? this.state.recStyles.shadow.blur : undefined;
        const spread = this.state.recStyles.shadow.active
          ? this.state.recStyles.shadow.spread : undefined;
        const boxShadow = (this.state.recStyles.shadow.active && this.state.recStyles.shadow.color !== '')
          ? `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`
          : undefined;
        const WebkitFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const MozFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const OFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const msFilter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
        const filter = this.state.recStyles.backgroundBlur.active
          ? `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';

        style = {
          boxShadow,
          WebkitFilter,
          MozFilter,
          OFilter,
          msFilter,
          filter,
        };

        if (this.state.recStyles.backgroundColor.gradient.from !== '' && this.state.recStyles.backgroundColor.active) {

          style.background = bgColor.background;

        } else if (this.state.recStyles.backgroundColor.active) {

          style.backgroundColor = bgColor.backgroundColor;

        }

        let solid = '#000000';
        if (this.state.recStyles.font.fontColor.color !== '') {

          solid = this.state.recStyles.font.fontColor.color;

        }

        const fontColor = formColor(
          {
            solid,
          },
          undefined,
          this.state.recStyles.font.fontColor.opacity,
        );

        style.color = fontColor.backgroundColor;

      }

      const burgerColor = formColor(
        { solid: this.state.recStyles.burgerColor.color },
        false,
        this.state.recStyles.burgerColor.opacity,
      );

      let infoRow;
      if (this.state.infoRow && this.state.infoRow.active) {

        const solid = this.state.infoRow.style.color || '#000000';
        const opacity = isScrolling === false
          ? this.state.infoRow.style.opacity : 1;
        const infoColor = formColor(
          { solid },
          undefined,
          opacity,
        );

        infoRow = (
          <div
            style={infoColor}
            className={`infoRow ${styles.infoRow}`}
          >
            { HTMLParser(`<span>${this.state.infoRow.data}</span>`) }
          </div>
        );

      }

      let secondaryNav;
      if (this.state.secondaryLinks && this.state.secondaryLinks.length > 0) {

        secondaryNav = (
          <NavigationLinks
            links={this.state.secondaryLinks}
            color={style.color}
            color0={this.state.themeData.colors[0]}
            themeData={this.state.themeData}
            buttons={this.props.pageContext.buttons}
            images={this.props.pageContext.images}
            secondary
          />
        );

      }

      let navbar;
      let logo;
      let menu;
      let noLinks;
      if (this.state.layout === 'left') {

        navbar = 'navbar';
        logo = 'logoLeft';
        menu = 'menuRight';

      } else if (this.state.layout === 'center') {

        logo = 'logoCenter';
        navbar = 'navbarCenter';
        menu = 'menuCenter';

      } else if (this.state.layout === 'right') {

        navbar = 'navbarReverse';
        logo = 'logoRight';
        menu = 'menuLeft';

      } else if (this.state.layout === 'divided') {

        if (
          (this.state.links && this.state.links.length > this.state.burgerBreak)
          || (!this.state.matches && this.state.links && this.state.links.length > 0)
        ) {

          navbar = 'navbar';
          menu = 'menuRight';
          logo = 'logoDividedBurger';

        } else {

          navbar = `navbarDivided${secondaryNav ? 'Secondary' : ''}`;

          if (this.state.links && this.state.links.length === 0) {

            noLinks = 'navbarDividedNoLinks';

          }

          menu = 'menuDivided';
          logo = 'logoDivided';

        }

      }

      let navStyle;
      if (
        this.state.fixed_top === false
        && this.state.overlay === false
      ) {

        navStyle = 'sectionNavbar';

      } else if (
        this.state.fixed_top === true
        && this.state.overlay === false
      ) {

        navStyle = 'sectionNavbarTop';

      } else if (
        this.state.fixed_top === true
        && this.state.overlay === true
      ) {

        navStyle = 'sectionNavbarTopOverlay';

      } else if (
        this.state.fixed_top === false
        && this.state.overlay === true
      ) {

        navStyle = 'sectionNavbarOverlay';

      }

      const navWrapper = this.state.full === true ? 'navbarFull' : 'navbarPartial';
      const layout = this.state.layout.charAt(0).toUpperCase() + this.state.layout.slice(1);
      const target = '/';
      const shrink = isScrolling && this.state.logo && this.state.logo.active && this.state.layout !== 'center';
      const linkStyle = shrink ? { display: 'block', lineHeight: 0 } : undefined;
      const logoHeight = {
        height: `${this.state.logo && this.state.logo.active && this.state.logo.logoHeight !== undefined ? this.state.logo.logoHeight : 60}px`,
      };

      const lg = (
        <div className={styles[logo]} style={logoHeight}>
          <Link to={target} className={styles.linkStyle} style={linkStyle}>
            {
              !this.state.logo || (this.state.logo && this.state.logo.active !== true)
                ? (
                  <span style={titleStyle}>
                  {this.state.sitename}
                </span>
                )
                : (
                  <Image
                    id={this.state.logo.id}
                    url={this.state.logo.src}
                    CDNLink={this.state.logo.CDNLink}
                    alt="Logo"
                    imageClass={`navbarLogo${shrink ? 'Scrolling' : ''}`}
                    images={this.props.pageContext.images}
                  />
                )
            }
          </Link>
        </div>
      );

      // Domain and preview params from FE not needed
      let nav;
      if (this.state.links && this.state.links.length > 0) {

        nav = (
          <NavigationLinks
            links={this.state.links}
            burgerBreak={this.state.burgerBreak}
            burgerColor={burgerColor}
            divider={this.state.divider}
            linkStyle={this.state.linkStyle}
            layout={layout}
            menuStyle={menu}
            lg={lg}
            color={style.color}
            color0={this.state.themeData.colors[0]}
            themeData={this.state.themeData}
            matches={this.state.matches}
            images={this.props.pageContext.images}
            secondaryNav={secondaryNav}
            buttons={this.props.pageContext.buttons}
            infoRow={infoRow !== undefined}
          />
        );

      }

      let combinedNavs;
      let topSecondary;
      if (!secondaryNav || !this.state.matches) combinedNavs = nav;
      else {

        if (this.state.layout === 'center' || this.state.layout === 'divided') {

          combinedNavs = nav;
          topSecondary = (
            <div className={styles.topSecondary}>
              { secondaryNav }
            </div>
          );

        } else if (this.state.layout === 'left' || this.state.layout === 'right') {

          combinedNavs = (
            <div className={styles.combinedNavs}>
              { nav }
              { secondaryNav }
            </div>
          );

        }

      }

      result = (
        <div className={`${navStyle} ${styles[navStyle]}`} id="NavigationBarAnchor">
          { infoRow }
          <div
            className={styles[`navContainer${isScrolling && this.state.layout !== 'center' ? 'Small' : secondaryNav && (this.state.layout === 'center' || this.state.layout === 'divided') ? 'Secondary' : ''}`]}
          >
            <div style={style} className={styles.background} />
            <div className={styles[navWrapper]}>
              <div className={`${this.state.full ? '' : 'container'} ${styles[navbar]} ${noLinks !== undefined ? styles[noLinks] : ''}`}>
                { topSecondary }
                {
                  (
                    this.state.layout !== 'divided'
                    || (this.state.links && this.state.links.length === 0)
                    || (
                      this.state.layout === 'divided'
                      && (
                        (this.state.links && this.state.links.length > this.state.burgerBreak)
                        || !this.state.matches
                      )
                    )
                  )
                  && (
                    <React.Fragment>
                      { lg }
                    </React.Fragment>
                  )
                }
                { combinedNavs }
              </div>
            </div>
          </div>
        </div>
      );

    }

    return result;

  }

}

NavigationBar.propTypes = {
  site: PropTypes.shape({}),
};

export default CSSModules(NavigationBar, styles, { allowMultiple: true });
